<template>
  <div class="g-container">
    <div ref="active_popup">
      <Popup v-if="isAuthUserAsAdmin" v-click-outside="closePopup" :item-id="popup.itemId" :show-popup="popup.show"
        @onEdit="editItem(popup.itemId)" @onDelete="confirmDelete"></Popup>
    </div>
    <ConfirmPopup :text="`Удалить ${reference.name} ${popup.itemName}?`" :show="popup.confirm"
      @close="popup.confirm = false" @confirm="deleteItem(popup.itemId)"></ConfirmPopup>
    <AddItem :visible="modal.show" :per-page="perPage" :initial-num="modal.num" :initial-name="modal.item"
      :initial-image-id="modal.imageId" :initial-name-en="modal.nameEn" :initial-code="modal.code"
      :initial-direction="modal.direction" :item-name="reference.name" :item-id="popup.itemId" :mode="modal.type"
      :item-type="reference.type" @close="modal.show = false"></AddItem>
    <div class="users-page">
      <h2 class="users-page__title">{{ reference.title }}</h2>
      <div class="flex justify-between items-start">
        <button class="btn btn-green" v-if="isAuthUserAsAdmin" @click="addItem">Добавить {{ reference.name }}</button>
        <Search :is-not-found="isNotFound" :key="reference.type" @query="changeQueryString" place-holder="Поиск">
        </Search>
      </div>
      <div class="table-wrapper">
        <table class="users-table">
          <thead class="users-table__head">
            <tr>
              <th>
                <div class="users-table__box" @click="order = changedOrderState('name', '-name', order)">
                  <span class="users-table__title">{{ nameCapital }}</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-name', 'opacity-0': !['name', '-name'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th v-if="reference.type === 'partner-attributes' || reference.type === 'partner-types'">
                <div class="users-table__box"
                  @click="order = changedOrderState('direction_name', '-direction_name', order)">
                  <span class="users-table__title">Направление</span>
                  <img
                    :class="{ 'users-table-rotate__icon': order === '-direction_name', 'opacity-0': !['direction_name', '-direction_name'].includes(order) }"
                    class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                </div>
              </th>
              <th v-if="reference.type === 'socials' || reference.type === 'partner-types'">
                <div class="users-table__box">
                  <span class="users-table__title">Иконка</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in itemsList" :key="index">
              <td @contextmenu.prevent="showPopup(item.id, `name${item.id}`)">
                <div class="users-box" :ref="`name${item.id}`">
                  <span class="users-table__desc truncate">{{ item.name }}</span>
                </div>
              </td>
              <td v-if="reference.type === 'partner-types' || reference.type === 'partner-attributes'"
                @contextmenu.prevent="showPopup(item.id, `direction${item.id}`)">
                <div class="users-box" :ref="`direction${item.id}`">
                  <span class="users-table__desc truncate">
                    {{ getDirectionName(item.direction) }}
                  </span>
                </div>
              </td>
              <td v-if="reference.type === 'socials' || reference.type === 'partner-types'"
                @contextmenu.prevent="showPopup(item.id, `link${item.id}`)">
                <div class="users-box" :ref="`link${item.id}`">
                  <span class="users-table__desc truncate">
                    <img v-if="reference.type === 'socials'" class="reference__image"
                      :src="item.image ? item.image.thumbnail_link : item.image_link" alt="">
                    <svg v-if="reference.type === 'partner-types'" class="reference__image" fill="#58BCAF"
                      :data-src="item.image ? item.image.thumbnail_link : item.link" alt=""></svg>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination :current-page="page" :total-pages="countPages" :per-page="itemsList.length"
        :is-next-users-page="isNextPage" :is-previous-users-page="isPreviousPage" @onNext="changeCurrentPage"
        @onPrevious="changeCurrentPage"></Pagination>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import Pagination from '@/components/Pagination.vue';
import Search from '@/components/Search.vue';
import Popup from '@/components/RegionsPopup.vue';
import ConfirmPopup from '@/components/ConfirmPopup.vue';
import AddItem from '@/components/AddItem.vue';

export default {
  name: 'ReferenceTable',
  directives: {
    ClickOutside
  },
  components: {
    Search,
    Pagination,
    Popup,
    ConfirmPopup,
    AddItem
  },
  data() {
    return {
      queryString: '',
      isNotFound: false,
      reference: {
        title: '',
        name: '',
        type: ''
      },
      popup: {
        show: false,
        confirm: false,
        itemId: null,
        rightPosition: false
      },
      modal: {
        show: false,
        num: null,
        item: '',
        type: 'add',
        imageId: '',
        nameEn: '',
        code: '',
        direction: ''
      },
      order: null,
      page: 0,
      perPage: 10,
      isNextPage: false,
      isPreviousPage: false,
      countPages: 1
    }
  },
  async mounted() {
    await this.initComponent(this.$route);
  },
  beforeUnmount() {
    this.itemsList = [];
    this.reference.name = '';
    this.reference.title = '';
    this.queryString = '';
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'directions'
      || to.name === 'objects'
      || to.name === 'socials'
      || to.name === 'countries'
      || to.name === 'partner-types'
      || to.name === 'partner-attributes') {
      this.initComponent(to);
    }
    next();
  },
  watch: {
    async order() {
      this.page = 0;
      await this.fetchItems();
    },
    async queryString(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 0;
        await this.fetchItems();
      }
    }
  },
  methods: {
    async initComponent(route) {
      this.queryString = '';
      switch (route.name) {
        case 'directions':
          this.reference.title = 'Направления';
          this.reference.name = 'направление';
          break;
        case 'objects':
          this.reference.title = 'Виды объектов';
          this.reference.name = 'вид объекта';
          break;
        case 'partner-types':
          this.reference.title = 'Типы партнеров';
          this.reference.name = 'Тип партнера';
          break;
        case 'partner-attributes':
          this.reference.title = 'Признаки партнеров';
          this.reference.name = 'признак партнера';
          break;
        case 'geo-regions':
          this.reference.title = 'Географические регионы';
          this.reference.name = 'географический регион';
          break;
        case 'countries':
          this.reference.title = 'Страны';
          this.reference.name = 'страна';
          break;
        case 'socials':
          this.reference.title = 'Социальные сети';
          this.reference.name = 'социальная сеть';
          break;
        default:
          this.$services.MessageService.error('Неизвестный тип данных');
          setTimeout(() => { this.$route.push({ name: 'partners-list' }); }, 2000);
      }

      this.reference.type = route.name;
      if (this.reference.type === 'partner-types' || this.reference.type === 'partner-attributes') {
        await this.$store.dispatch('directions/fetchDirections');
      }
      await this.fetchItems();
    },
    changedOrderState(ascName, descName, currentState) {
      if (currentState !== ascName && currentState !== descName) {
        return ascName;
      }
      if (currentState === ascName) {
        return descName;
      }
      if (currentState === descName) {
        return null;
      }
    },
    showPopup(itemId, ref) {
      const element = this.$refs[ref][0];
      if (element) {
        element.appendChild(this.$refs['active_popup']);
        this.popup.itemId = itemId;
        this.popup.show = true;
      }
    },
    closePopup() {
      this.popup.show = false;
    },
    confirmDelete() {
      this.popup.show = false;
      const itemToDelete = this.itemsList.find(item => item.id === this.popup.itemId);
      if (itemToDelete) {
        this.popup.itemName = itemToDelete.name;
        this.popup.confirm = true;
      }
    },
    async deleteItem(id) {
      this.popup.confirm = false;
      try {
        if (this.reference.type === 'directions') {
          await this.$services.DirectionService.deleteDirection(id);
        } else if (this.reference.type === 'objects') {
          await this.$services.ObjectService.deleteObjectType(id);
        } else if (this.reference.type === 'partner-types') {
          await this.$services.PartnerService.deletePartnerType(id);
        } else if (this.reference.type === 'partner-attributes') {
          await this.$services.PartnerService.deletePartnerAttribute(id);
        } else if (this.reference.type === 'geo-regions') {
          await this.$services.RegionService.deleteGeoRegion(id);
        } else if (this.reference.type === 'countries') {
          await this.$services.CountryService.deleteCountry(id);
        } else if (this.reference.type === 'socials') {
          await this.$services.SocialsService.deleteSocial(id);
        }
        await this.fetchItems();
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error(`Удаление невозможно – ${this.reference.name} имеет связанные системы, продукты или регионы`);
      }
    },
    addItem() {
      this.modal.type = 'add';
      this.modal.num = this.itemsList.length + 1;
      this.modal.item = '';
      this.modal.link = '';
      this.modal.imageId = '';
      this.modal.nameEn = '';
      this.modal.code = '';
      this.modal.show = true;
    },
    editItem(id) {
      const itemToEdit = this.itemsList.find(item => item.id === id);
      if (itemToEdit) {
        const index = this.itemsList.findIndex(item => item.id === id);
        this.modal.num = index + 1;
        this.modal.type = 'edit';
        this.modal.item = itemToEdit.name;
        if (this.reference.type === 'socials' || this.reference.type === 'partner-types') {
          this.modal.imageId = itemToEdit.image ? itemToEdit.image.id : null;
        }
        if (this.reference.type === 'directions') {
          this.modal.nameEn = itemToEdit.name_en;
          this.modal.code = itemToEdit.code;
        }
        if (this.reference.type === 'partner-types' || this.reference.type === 'partner-attributes') {
          this.modal.direction = itemToEdit.direction;
        }
        this.modal.show = true;
      }
    },
    changeQueryString(string) {
      this.queryString = string;
    },
    async fetchItems() {
      let params = {
        page: this.page,
        page_size: this.perPage
      };
      const ordering = this.order;
      if (this.queryString.length === 0 && !ordering) {
        params = {
          ...params
        };
      } else if (this.queryString.length === 0 && ordering) {
        params = {
          ...params,
          ordering
        };
      } else if (this.queryString.length > 0 && !ordering) {
        params = {
          ...params,
          search_query: this.queryString
        };
      } else if (this.queryString.length > 0 && ordering) {
        params = {
          ...params,
          search_query: this.queryString,
          ordering
        };
      }
      let data = [];
      switch (this.reference.type) {
        case 'directions':
          data = await this.$services.DirectionService.getDirections(params);
          await this.$store.dispatch('directions/setDirections', data.results);
          break;
        case 'objects':
          data = await this.$services.ObjectService.getObjectTypes(params);
          await this.$store.dispatch('facility/setFacilityTypes', data.results);
          break;
        case 'partner-types':
          data = await this.$services.PartnerService.getPartnerTypes(params);
          await this.$store.dispatch('partner/setPartnerTypes', data.results);
          break;
        case 'partner-attributes':
          data = await this.$services.PartnerService.getPartnerAttributes(params);
          await this.$store.dispatch('partner/setPartnerAttributes', data.results);
          break;
        case 'countries':
          data = await this.$services.CountryService.getCountries(params);
          await this.$store.dispatch('country/setCountries', data.results);
          break;
        case 'socials':
          data = await this.$services.SocialsService.getSocials(params);
          this.$store.dispatch('social/setSocials', data.results);
          break;
        default:
          break;
      }
      this.isNextPage = !!data.next || null;
      this.isPreviousPage = !!data.previous || null;
      this.countPages = Math.ceil(data.count / this.perPage) || 1;
    },
    async changeCurrentPage(page) {
      this.page = page;
      await this.fetchItems();
    },
    getDirectionName(id) {
      const direction = this.directions.find(item => item.id === id);
      return direction ? direction.name : '';
    }
  },
  computed: {
    isAuthUserAsAdmin() {
      const adminRoleId = this.$store.getters['user/getAdminRoleId'];
      const authUser = this.$store.getters['auth/getAuthUser'];
      return authUser.role_id === adminRoleId;
    },
    directions() {
      return this.$store.getters['directions/getDirections'];
    },
    nameCapital() {
      return this.reference.name.charAt(0).toUpperCase() + this.reference.name.substring(1);
    },
    itemsList() {
      if (this.reference.type === 'directions') {
        return this.$store.getters['directions/getDirections'];
      }
      if (this.reference.type === 'objects') {
        return this.$store.getters['facility/getFacilityTypes'];
      }
      if (this.reference.type === 'partner-types') {
        return this.$store.getters['partner/getPartnerTypes'];
      }
      if (this.reference.type === 'partner-attributes') {
        return this.$store.getters['partner/getPartnerAttributes'];
      }
      if (this.reference.type === 'geo-regions') {
        return this.$store.getters['geo_region/getGeoRegions'];
      }
      if (this.reference.type === 'countries') {
        return this.$store.getters['country/getCountries'];
      }
      if (this.reference.type === 'socials') {
        return this.$store.getters['social/getSocials'];
      }
      return [];
    }
  }
}
</script>

<style scoped>
.table-num {
  width: 5%;
}

.max-width-100 {
  max-width: 100px;
}

.reference__image {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
</style>
